<template>
  <div class="topicDetails">
    <Breadcrumb></Breadcrumb>
    <detail :question_id="question_id"></detail>
  </div>
</template>

<script>
import Detail from "@/views/api/teaching/question/detail";
export default {
  components: {
    Detail,
  },
  data() {
    return {
      question_id: "",
    };
  },
  // mounted() {
  //   if (location.href.indexOf("#reloaded") == -1) {
  //     location.href = location.href + "#reloaded";
  //     // location.reload();
  //     this.$router.go(0)
  //   }
  // },
  methods: {
    routeUpdate() {
      this.question_id = this.$route.params.question_id || this.$route.query.id;
    },
  },
};
</script>
